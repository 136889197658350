import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../sidebar/Sidebar";
import UploadModal from "../UploadModal/UploadModal";
import DataG from "../DataGenieeCpm/DataG";
import GuidelinesModal from "../GuidelinesModal/GuidelinesModal";
import { useTheme } from "../ThemeContext";

const DataGenie = () => {
  const [sessionId, setSession] = useState(false);
  const [isopen, setOpen] = useState(false);

  const [showGuidelines, setShowGuidelines] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [fileResponse, setFileResponse] = useState(
    [
      {
          "fileName": "sage_export_1721400108501.xlsx",
          "sheets": [
              "Invoices (sage_export_1721400108501.xlsx)"
          ]
      },
      {
          "fileName": "invoice_report_1721400644504.xlsx",
          "sheets": [
              "Report (invoice_report_1721400644504.xlsx)"
          ]
      }
  ]
  );
  const [files, setFiles] = useState([]);
  const [notifyMessage, setNoftifyMessage] = useState(false);
  const [show, onHide] = useState(false);

  const handleGuidelinesAgree = () => {
    setShowGuidelines(false);
    onHide(true);
  };

  const handleGuidelinesClose = () => {
    setShowGuidelines(false);
    onHide(true);
  };

  const handleUploadClose = () => {
    setShowUpload(false);
  };

  const { isDarkMode } = useTheme();

  return (
    <Container fluid className="p-0">
      <Row className={`g-0  m-0 ${isDarkMode ? 'dark-mode' : 'bg-light'}`}>
        <Col md={3} lg={2} className="d-none d-md-block">
          <Sidebar setFileResponse={setFileResponse} setFiles={setFiles} files={files} />
        </Col>
        <Col
          md={9}
          lg={10}
          className="mt-3 position-relative"
          style={{ borderTopLeftRadius: "35px", backgroundColor:`${isDarkMode ? "#0C0A0C":"#FFF" }` }}
        >
          <DataG onHide={onHide} sessionId={sessionId} notifyMessage={notifyMessage} fileResponse={fileResponse} />
        </Col>
      </Row>
      <GuidelinesModal 
        show={showGuidelines} 
        onAgree={handleGuidelinesAgree} 
        onClose={handleGuidelinesClose}
      />
      <UploadModal
        show={show}
        onHide={onHide}
        setFiles={setFiles}
        files={files}
        setFileResponse={setFileResponse}
        setSession={setSession}
        setNoftifyMessage={setNoftifyMessage}
      
      />
    </Container>
  );
};

export default DataGenie;