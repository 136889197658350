import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./guidelinesmodal.css";
import { useTheme } from "../ThemeContext";

const GuidelinesModal = ({ show, onAgree, onClose }) => {

    const { isDarkMode } = useTheme();
  return (
    <Modal
      show={show}
      onHide={onClose}
      className="guidelinesmodal"
      size="lg"
      centered
      dialogClassName={` ${isDarkMode ? "dark-mode" : ""}`}
      contentClassName={`modal-content-animation ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <Modal.Header closeButton>
        <Modal.Title>DataGeniee Guidelines</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ alignItems: "start" }}>
        <h5>1. Format Guidelines</h5>
        <p>
          Ensure all sheets contain only table-like data with rows, columns, and
          headers.
        </p>

        <h5>2. Extraneous Data:</h5>
        <p>Do not include data outside the table (before, above, or after).</p>

        <h5>3. File Format:</h5>
        <p>All columns must have headers.</p>

        <h5>4. Error-Free Data:</h5>
        <p>
          Avoid common Excel errors (e.g., #DIV/0!, #NUM!,#VALUE!, #N/A, #NAME?,
          #REF!, #NULL!).
        </p>

        <h5>5. Date Fields:</h5>
        <p>
          Date fields must not be null or contain errors or multi date formats.
        </p>

        <h5>6. Compliance:</h5>
        <p>
          Sheets that do not meet these criteria will be excluded from
          selection.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onAgree} className="w-25 py-3 rounded-pill "
        style={{ backgroundColor: "#8A2BE2", borderColor: "#8A2BE2" }}>
          I Agree
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GuidelinesModal;
