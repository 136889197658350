import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TermsAndConditionText } from './TermsAndConditionText';
import './Termsandconditions.css'; // Import the custom CSS

const TermsAndConditionsModal = ({ show, onHide, onAgree }) => {
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const modalBodyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (modalBodyRef.current) {
        const scrollHeight = modalBodyRef.current.scrollHeight - modalBodyRef.current.scrollTop;
        const totalHeight = modalBodyRef.current.clientHeight;
        const tolerance = 2; // Define your tolerance range here
        const atBottom = Math.abs(scrollHeight - totalHeight) <= tolerance;
        setScrolledToEnd(atBottom);
      }
    };

    if (show && modalBodyRef.current) {
      modalBodyRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (modalBodyRef.current) {
        modalBodyRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalBodyRef} className="modal-body-scrollable">
        <TermsAndConditionText />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button 
          variant="primary" 
          onClick={onAgree} 
          style={{ backgroundColor: "#8A2BE2" }} 
          disabled={!scrolledToEnd}
        >
          Agree
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditionsModal;
