// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-div {
    text-align: center;
  }

  .dark-mode-black li{
    background-color: black !important;}
  `, "",{"version":3,"sources":["webpack://./src/components/multiSelectDropdown/Multiselect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,kCAAkC,CAAC","sourcesContent":[".centered-div {\n    text-align: center;\n  }\n\n  .dark-mode-black li{\n    background-color: black !important;}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
