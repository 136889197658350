import React, { useState, useEffect } from "react";
import { Modal, Button, Card, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./pricingmodal.css"; // Include your custom CSS file
import { useTheme } from "../ThemeContext";
import axiosInstance from "../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../helpers/UserContext";
const planL = [
  {
    planId: 2,
    planName: "Day plan",
    price: "150₹+GST",
    originalPrice: "299₹+GST",
    features: [
      "Unlimited queries",
      "Unlimited datasets",
      "Auto connection of multiple datasets",
      "Max size of one upload 40mb",
      "Max of 10 sheets per upload",
      "Unlimited table, charts or response",
    ],
  },
  {
    planId: 3,
    planName: "Weekly plan",
    price: "699₹+GST",
    originalPrice: "1199₹+GST",
    features: [
      "Unlimited queries",
      "Unlimited datasets",
      "Auto connection of multiple datasets",
      "Max size of one upload 40mb",
      "Max of 10 sheets per upload",
      "Unlimited table, charts or response",
    ],
  },
  {
    planId: 4,
    planName: "Monthly plan",
    price: "1699₹+GST",
    originalPrice: "2999₹+GST",
    features: [
      "Unlimited queries",
      "Unlimited datasets",
      "Auto connection of multiple datasets",
      "Max size of one upload 40mb",
      "Max of 10 sheets per upload",
      "Unlimited table, charts or response",
    ],
  },
];

const PricingModal = ({ show, onHide }) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [planList, setPlanList] = useState(planL);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { userData } = useUserContext();

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await axiosInstance.get("/mid-doc/doc-genie/plans"); // Use the Axios instance
      const { planList } = response.data;
      setPlanList(planList);
    } catch (error) {
      console.error("Error fetching plans:", error);
      // Check if the error response contains "Invalid session" with status code 401
      if (
        error.response &&
        error.response.data.msg === "Invalid session" &&
        error.response.status === 401
      ) {
        navigate("/");
      }
    }
  };
  const handleGetStarted = async (planId) => {
    if(!userData?.verifiedUser){
        navigate("/auth/confirm-mail", { state: { userMail: userData?.email, from: 'Login' } });
        return;
    }
    try {
      const response = await axiosInstance.get(`/mid-doc/doc-genie/checkout?planId=${planId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const url = response?.data?.sessionId;
      const primeUser = response?.data?.isPrime;
      if (url) {
        window.location.href = url;
        onHide();
      }
      else if(primeUser && userData?.verifiedUser){
        onHide();
        // navigate('/dashboard');
      }
      else{
        // navigate("/auth/confirm-mail", { state: { userMail: userData?.email, from: 'Login' } });
      }
    } catch (error) {
      console.error("Error:", error);
      if (
        error.response &&
        error.response.data.msg === "Invalid session" &&
        error.response.status === 401
      ) {
        navigate("/");
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      dialogClassName={`modal-90w ${isDarkMode ? 'dark-mode' : ''}`}
      contentClassName={`modal-content-animation ${isDarkMode ? 'dark-mode' : ''}`}
    >
    <Modal.Header closeButton className={`modal-header-custom ${isDarkMode ? 'dark-mode' : ''}`}>
    <Modal.Title as="h2" className="w-100 text-center">
      Pricing Plans
    </Modal.Title>
  </Modal.Header>
  <Modal.Body className={`pricing-modal-body ${isDarkMode ? 'dark-mode' : ''}`}>
    <p className="text-center mb-4">Choose What Fits You</p>
    <Container>
      <Row className="justify-content-center">
        {planList?.map((plan, index) => (
          <Col key={index} xs={12} md={3} className="mb-4">
            <Card
              className={`h-100 d-flex flex-column pricing-card ${
                hoveredCard === index ? "shadow-lg" : ""
              } ${isDarkMode ? 'dark-mode' : ''}`}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
              style={{
                transition: "all 0.3s ease-in-out",
                transform:
                  hoveredCard === index ? "scale(1.05)" : "scale(1)",
                backgroundColor:
                  hoveredCard === index ? "#8a2be2" : (isDarkMode ? "#333" : "white"),
                color: hoveredCard === index || isDarkMode ? "white" : "black",
              }}
            >
              <Card.Body className="d-flex flex-column text-center">
                <Card.Title className="mb-3">{plan?.planName}</Card.Title>
                <Card.Text
                  style={{
                    color: hoveredCard === index || isDarkMode ? "white" : "black",
                    textDecoration: "line-through",
                  }}
                >
                  {plan?.originalPrice}
                </Card.Text>
                <h2 className="mb-3">{plan.price}</h2>
                <div className="pricing-benefits mb-4 text-left">
                  {plan?.features.map((benefit, benefitIndex) => (
                    <p
                      key={benefitIndex}
                      className="mb-2 text-left flex items-start"
                      style={{
                        color: hoveredCard === index || isDarkMode ? "white" : "black",
                      }}
                    >
                      <span className="mr-2">✓</span>
                      <span>{benefit}</span>
                    </p>
                  ))}
                </div>
                <Button
                  variant={hoveredCard === index || isDarkMode ? "light" : "dark"}
                  className="w-100"
                  onClick={()=> handleGetStarted(plan.planId)}
                >
                  Subscribe
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  </Modal.Body>
</Modal>
);
};

export default PricingModal;
