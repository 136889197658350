import React from 'react';

// Functional component to truncate file names
const TruncatedFileName = ({ fileName, maxLength }) => {
  // Function to truncate the file name
  const truncateFileName = (fileName, maxLength) => {
    if (fileName?.length <= maxLength) return fileName;
  
    const firstPart = fileName.slice(0, maxLength / 2);
    const lastPart = fileName.slice(fileName.length - maxLength / 2);
    return `${firstPart}...${lastPart}`;
  };

  // Truncated file name
  const truncatedName = truncateFileName(fileName, maxLength);

  return (
    <span>{truncatedName}</span>
  );
};

export default TruncatedFileName;
