// UploadModal.js
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import uploadIcon from "../../icons/Upload.svg";
import errorIcon from "../../icons/Error.svg";
import "./UploadModal.css"; // Import the CSS file
import axiosInstance from "../../services/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../ThemeContext";
import * as XLSX from "xlsx";

const UploadModal = ({ show, onHide, setFiles, files, setFileResponse, setSession, setNoftifyMessage }) => {
  const navigate = useNavigate();

  const [type, setFileType] = useState("");
  const [error, setError] = useState(false);
  const [uploadstatus, setUploadstatus] = useState(true);
  const { isDarkMode } = useTheme();
  const [message, setMesage] = useState('File should be less than 40MB');

  const location = useLocation();
  let isDatageniee = location.pathname === '/data-geniee';

  useEffect(() => {
    setFileType(isDatageniee ? ".xlsx, .xls, .xlsm, .xlsb, .csv" : '.pdf');
  }, [isDatageniee]);
console.log('isDatageniee', isDatageniee);
  // const { isDarkMode } = useTheme();
  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const readExcelFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        resolve(workbook.SheetNames.length);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (event) => {
    setUploadstatus(true);
    const files = Array.from(event.target.files);

    const hasUnsupportedFiles = files.some(file => {
      const extension = file.name.split('.').pop().toLowerCase();
      return !type.includes(`.${extension}`);
    });

    if (hasUnsupportedFiles) {
      setMesage("Unsupported file type.");
      setUploadstatus(false);
      return;
    }

    if (files.length > 4) {
      setMesage("Upload less than or equal to 4 files");
      setUploadstatus(false);
      return;
    }

    if (isDatageniee) {
      const xlsbFiles = files.filter(file => file.name.split('.').pop().toLowerCase() === 'xlsb');
      const otherFiles = files.filter(file => file.name.split('.').pop().toLowerCase() !== 'xlsb');

      let totalSheetCount = 0;

      for (const file of otherFiles) {
        totalSheetCount += await readExcelFile(file);
      }

      if (totalSheetCount > 10) {
        setMesage("Total number of sheets across files cannot exceed 10");
        setUploadstatus(false);
        return;
      } else {
        console.log('F0');
        setFiles([...xlsbFiles, ...otherFiles]);
        setFileResponse([]);
        setUploadstatus(true);
        onHide(false);
      }
    } else {
      console.log('F1');
      setFiles(files);
      onHide(false);
      setFileResponse([]);
      setUploadstatus(true);
    }
  };
  const fileUpload =async(data)=>{

    const formData = new FormData();
    data.forEach(f => formData.append("file", f));

    try {
      const response = await axiosInstance.post("doc-genie/upload", formData);
      setFileResponse(response?.data?.files);
      setSession(response.data.sessionId);

      if (response?.data?.isPrime === false) {
        navigate("/auth/welcome");
      }

      if (response.status === 200 && response?.data?.message === "SUCCESS") {
        setNoftifyMessage({
          message: "Uploaded successfully",
          type: "success"
        });
      } else {
        setNoftifyMessage({
          message:"Failed to upload file",
          type:"danger"
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setNoftifyMessage({
        message: "Failed to upload file",
        type: "danger"
      });
      if (error.response?.status === 401 && error.response.data.msg === "Invalid session") {
        setNoftifyMessage({
          message:"Invalid Session, Please Login again",
          type: "danger"
        });
        
      }
    }

  }
  useEffect(() => {
    if (files.length) {
      fileUpload(files)
    }else{
      setError("Please select a file to upload", "error");
      return;
    }
  }, [files])
  
  const handleClose = ()=>{
   onHide(false)
  }
  console.log('LOFF', files);

  return (
    <Modal
    show={show}
    size="lg"
    onHide={() => onHide(false)}
    centered
    dialogClassName="custom-modal"
  >
      <Modal.Body className={`text-center p-4 position-relative d-flex flex-column justify-content-center rounded ${isDarkMode ? 'dark-mode' : ''} `}>
        <button className="close-button" onClick={() => onHide(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="upload-icon-wrapper mb-3">
          <label htmlFor="file-upload" className="file-upload-label">
            {uploadstatus ? (
              <img src={uploadIcon} alt="Upload" />
            ) : (
              <img src={errorIcon} alt="error" />
            )}
          </label>
        </div>
        {uploadstatus ? <h5>Upload a File</h5> : <h5>File Upload Failed!</h5>}
        {uploadstatus ? (
          <p className="text-muted">{message}</p>
        ) : (
          <p className="text-muted">{message}</p>
        )}
        <input
          type="file"
          accept={type}
          onChange={handleFileChange}
          className="file-upload-input"
          style={{ display: "none" }}
          id="file-upload"
          multiple
        />
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
