// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UploadModal.css */
.custom-modal .modal-dialog {
  max-width: 300px; 
  width: 90%; 
  height: 60vh !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin: 0 auto;
  cursor: pointer;
}

.file-upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-upload-input {
  display: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
 .custom-modal{
  max-width: 20% !important;
  height: 60vh !important;
}

.custom-modal{
  .modal-content{
  height: 60vh !important;
}}

.text-muted {
  color: #6c757d; 
}

.dark-mode .text-muted {
  color: #b2b2b2 !important; 
}

@media (max-width: 768px){
  .custom-modal{
    max-width: 100% !important;
    height: 60vh !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UploadModal/UploadModal.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,gBAAgB;EAChB,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;CACC;EACC,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE;EACA,uBAAuB;AACzB,CAAC;;AAED;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,0BAA0B;IAC1B,uBAAuB;EACzB;AACF","sourcesContent":["/* UploadModal.css */\n.custom-modal .modal-dialog {\n  max-width: 300px; \n  width: 90%; \n  height: 60vh !important;\n}\n\n.modal-body {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.upload-icon-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  background-color: #f0f0f0;\n  margin: 0 auto;\n  cursor: pointer;\n}\n\n.file-upload-label {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  cursor: pointer;\n}\n\n.file-upload-input {\n  display: none;\n}\n\n.close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background: none;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n}\n .custom-modal{\n  max-width: 20% !important;\n  height: 60vh !important;\n}\n\n.custom-modal{\n  .modal-content{\n  height: 60vh !important;\n}}\n\n.text-muted {\n  color: #6c757d; \n}\n\n.dark-mode .text-muted {\n  color: #b2b2b2 !important; \n}\n\n@media (max-width: 768px){\n  .custom-modal{\n    max-width: 100% !important;\n    height: 60vh !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
