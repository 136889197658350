// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-alert {
    max-width: 500px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the alert */
    padding: 10px 20px; /* Adjust padding for better look */
  }

  @media ( max-width:768px ){
    .main-logo{
      display: none !important;
    }
    .sub-logo{
      display: inherit !important;
    }
  }

  @media(min-width:769px){
    .sub-logo{
      display: none !important;
    }
    .main-logo{
      display: inherit !important;
    }
  }

`, "",{"version":3,"sources":["webpack://./src/components/DataGenieeCpm/DataG.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,+BAA+B;IACjD,cAAc,EAAE,qBAAqB;IACrC,kBAAkB,EAAE,mCAAmC;EACzD;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B;IAC7B;EACF;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".custom-alert {\n    max-width: 500px; /* Adjust the width as needed */\n    margin: 0 auto; /* Center the alert */\n    padding: 10px 20px; /* Adjust padding for better look */\n  }\n\n  @media ( max-width:768px ){\n    .main-logo{\n      display: none !important;\n    }\n    .sub-logo{\n      display: inherit !important;\n    }\n  }\n\n  @media(min-width:769px){\n    .sub-logo{\n      display: none !important;\n    }\n    .main-logo{\n      display: inherit !important;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
