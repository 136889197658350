import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRobot,
  faCopy,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
// import MultiSelectComponent from "./helpers/MultiSelectDropDown";
// import Loader from "./helpers/Loader";
// import { LoadingProvider } from "./helpers/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";
// import StartToastifyInstance from "toastify-js";
// import InfoModal from "./helpers/InfoModal";
// import axiosInstance from "./axiosInstance";
// import ExcelDownloader from "./helpers/ExcelDownloader";
// import ChatLoader from "./helpers/ChatLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ChatBox.css";
import { Button, Form } from "react-bootstrap";
import { Send } from "../../icons/Send.svg";
import axiosInstance from "../../services/AxiosInstance";
import ExcelDownloader from "../../helpers/ExcelDownload";
import { useTheme } from "../ThemeContext";

function Base64Image({ base64String }) {
  return (
    <img
      src={`data:image/jpeg;base64,${base64String}`}
      alt="Base64"
      className="img-fluid"
    />
  );
}

const ChatBox = ({ sessionId, setNoftifyMessage, setMessages, messages }) => {
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isApiDone, setApiDone] = useState(false);
  const navigate = useNavigate();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [copyText, setCopyTest] = useState("");
  const location = useLocation();
  let isDocgeniee = location.pathname === "/doc-geniee";

  function handleInput() {
    const input = inputRef.current;
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleQuery = async () => {
    // event.preventDefault();
    const messageText = inputRef.current.value.trim(); // Get the trimmed message text
    console.log("messageText", messageText);
    if (messageText !== "") {
      // Check if the message text is not empty
      setApiDone(true);

      const currentTime = new Date().toLocaleTimeString();
      const newMessage = {
        sender: "User 1",
        type: "user",
        text: messageText,
        timestamp: currentTime,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      inputRef.current.value = "";
      // setInputHeight("auto");

      // Display loader for user 1's message
      const loaderMessage = {
        sender: "System",
        type: "chatloader",
        text: "Tailoring your answer...",
        timestamp: currentTime,
      };
      setMessages((prevMessages) => [...prevMessages, loaderMessage]);
      try {
        // Construct payload with session ID and message query
        const payload = {
          id: sessionId,
          query: messageText,
        };
  
        // Make POST request to API
        const response = await axiosInstance.post(
          "doc-genie/interaction",
          payload
        );
  
        if (response?.data?.isPrime === false) {
          alert("Subscription has ended, Redirecting to Subscription page");
          navigate('/auth/welcome');
        }
  
        // Remove the loader message from the messages array
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages.pop(); // Remove the last element
          return updatedMessages;
        });
  
        // Process API response
        // processApiResponse(response.data, currentTime);
      } catch (error) {
        console.error("Error:", error);
        if (
          error.response &&
          error.response.data.msg === "Invalid session" &&
          error.response.status === 401
        ) {
          alert("Invalid Session, Please Login again");
          navigate('/');
        }
      } finally {
        setApiDone(false); // Always set to false when the operation is complete
      }
    }
  };

  useEffect(scrollToBottom, [messages]);

const handleEdit = (text) => {
    inputRef.current.value = text;
  };

  const handleKeyDown = (event) => {
    console.log("ssddds");
    if (event.key === "Enter") {
      event.preventDefault();
      handleQuery();
    }
  };

  const handleCopyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyTest(index);
        setTimeout(() => {
          setCopyTest();
        }, 2000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  const { isDarkMode } = useTheme();

  return (
    <div className="container-fluid chat-container">
      {!sessionId && (
        <div className="row h-100">
          <div className="col-md-12 d-flex flex-column">
            <div className="messages-container flex-grow-1 overflow-auto p-3">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`d-flex 'justify-content-start' mb-3`}
                >
                  <div
                    className={`message ${message.type}`}
                    style={{ padding: "0 0px 0 70px" }}
                  >
                    <div
                      className={`avatar rounded-circle d-flex align-items-center justify-content-center me-2 ${
                        isDarkMode ? "darkavatar" : ""
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={message.type === "user" ? faUser : faRobot}
                      />
                    </div>
                    {message.type !== "dataframe" &&
                      message.type !== "plot" &&
                      message.type !== "error" &&
                      message.type !== "chatloader" && (
                        <div
                          className={`message-content p-3 rounded ${
                            isDarkMode ? "dark-mode-fade" : ""
                          }`}
                        >
                          <div
                            className={`mb-0 ${
                              message.type === "user"
                                ? "message.user.edit"
                                : "message-content.copy"
                            }`}
                          >
                            {message.text}
                          </div>
                          <hr style={{ color: "#B0B0B0" }} />
                          {message.type === "user" ? (
                            <div
                              className={`mb-0 message.user.edit2`}
                              style={{
                                position: "absolute",
                                bottom: "3px",
                                right: "10px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                onClick={() => handleEdit(message.text)}
                              />
                            </div>
                          ) : (
                            <div
                              className={`mb-0 message-content.copy2`}
                              style={{
                                position: "absolute",
                                bottom: "0",
                                right: "10px",
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                onClick={() =>
                                  handleCopyToClipboard(message.text, index)
                                }
                              />
                              <div
                                style={{ position: "relative", bottom: "3px" }}
                              >
                                {copyText === index ? "Copied!" : "Copy"}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {message.type === "plot" && (
                      <div className={`message-content p-3 rounded ${isDarkMode ? "dark-mode-fade" : ""
                          }`}>
                        <div
                          className={`plot-container  ${
                            isDarkMode ? "dark-mode-fade" : ""
                          }`}
                        >
                          <Base64Image base64String={message.image} />
                        </div>
                      </div>
                    )}
                    {message.type === "error" && (
                      <div className="message-content p-3 rounded">
                        <div className="mb-0">{message.text}</div>
                      </div>
                    )}
                    {message.type === "chatloader" && (
                      <div className={`message-content p-3 rounded ${
                                isDarkMode ? "dark-mode-fade" : ""
                              }`}>
                        <div className="mb-0">{message.text}</div>
                      </div>
                    )}
                    {message.type === "dataframe" &&
                      message.table?.length > 0 && (
                        <div
                          className={`message-content p-3 rounded ${
                            isDarkMode ? "dark-mode-fade" : ""
                          }`}
                        >
                          <div className="table-responsive">
                            <table
                              className={`table table-sm table-striped ${
                                isDarkMode ? "dark-mode-fade" : ""
                              }`}
                            >
                              <thead>
                                <tr>
                                  {Object.keys(message.table[0]).map(
                                    (header, index) => (
                                      <th key={index}>{header}</th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {message.table.map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {Object.values(row).map(
                                      (cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div
                              className={`mb-0 message.user.edit2`}
                              style={{
                                position: "absolute",
                                bottom: "3px",
                                right: "10px",
                              }}
                            >
                              <ExcelDownloader data={message} />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          </div>
        </div>
      )}
      <div className="m-0 position-fixed bottom-0 w-75">
        <div
          className={`d-flex justify-content-center align-items-center flex-column w-100  py-3 mt-2 ${
            isDarkMode ? "dark-mode-black" : "bg-white"
          }`}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "75%", position: "relative" }}
          >
            <Form.Control
              type="text"
              placeholder="Query it..."
              className="input-field input-area"
              ref={inputRef}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
              disabled={sessionId}
            />
            <Button
              variant="warning"
              className="send-button"
              type="submit"
              onClick={handleQuery}
            >
              <img src={require("../../icons/Send.svg").default} />
            </Button>
          </div>

          <p className="text-muted text-center small mt-2">
            *DataGenie outputs are generated by machine and may contain
            inaccuracies. Please verify critical information independently.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
