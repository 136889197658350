// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AuthForm.css */
.auth-form {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .auth-form .form-control {
    background-color: #f5f5f5;
    border: none;
    padding: 0.75rem;
  }
  
  .auth-form .submit-btn {
    background-color: #8A2BE2;
    border: none;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
  }

  .dark-mode {
    input,textarea,select {
    background-color: #2c2c2c;
    color: #ffffff;
    
  }
  
  
}
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-container .password-toggle-btn {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.password-input-container .form-control {
  padding-right: 40px;
}

.forgot-password{
float: right;
text-decoration: none;

}`, "",{"version":3,"sources":["webpack://./src/components/formController/AuthForm.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE;IACA,yBAAyB;IACzB,cAAc;;EAEhB;;;AAGF;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;AACA,YAAY;AACZ,qBAAqB;;AAErB","sourcesContent":["/* AuthForm.css */\n.auth-form {\n    background-color: white;\n    padding: 2rem;\n    border-radius: 10px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    max-width: 400px;\n  }\n  \n  .auth-form .form-control {\n    background-color: #f5f5f5;\n    border: none;\n    padding: 0.75rem;\n  }\n  \n  .auth-form .submit-btn {\n    background-color: #8A2BE2;\n    border: none;\n    width: 100%;\n    padding: 0.75rem;\n    margin-top: 1rem;\n  }\n\n  .dark-mode {\n    input,textarea,select {\n    background-color: #2c2c2c;\n    color: #ffffff;\n    \n  }\n  \n  \n}\n.password-input-container {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.password-input-container .password-toggle-btn {\n  position: absolute;\n  right: 10px;\n  background: none;\n  border: none;\n  cursor: pointer;\n  outline: none;\n}\n\n.password-input-container .form-control {\n  padding-right: 40px;\n}\n\n.forgot-password{\nfloat: right;\ntext-decoration: none;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
