// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.genieepro-font{
  font-weight:750 ;
  color:  #BABABA;
  

}

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  border-radius: 50%;
  background-color: #FFFFFF; /* Adjust the background color as needed */
 
}

.g-icon {
  
  color: #000000; /* Adjust the color as needed */
  font-size: large;

}

.brand-container {
  display: flex;
  align-items: center;
}
.sidebar-link {
  text-decoration: none;
  color: inherit; 
}

.sidebar-link:hover,
.sidebar-link:focus,
.sidebar-link:active {
  text-decoration: none;
  color: inherit;
}

.sidebar-link.active {
  color: inherit;
}


/* Sidebar.css */



.sidebar.dark-mode {
  background-color: #121212;
  color: #fff;
}

.brand-container {
  color: #000;
}

.brand-container.dark-mode {
  color: #fff;
}



.sidebar-link {
  color: #000;
}

.sidebar-link.dark-mode {
  color: #fff;
}

.delete-all {
  color: red;
}

.delete-all.dark-mode {
  color: #ff6b00;
}

.file-item {
  color: #000;
}

.file-item.dark-mode {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.css"],"names":[],"mappings":";;AAEA;EACE,gBAAgB;EAChB,eAAe;;;AAGjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW,EAAE,+BAA+B;EAC5C,YAAY,EAAE,gCAAgC;EAC9C,kBAAkB;EAClB,yBAAyB,EAAE,0CAA0C;;AAEvE;;AAEA;;EAEE,cAAc,EAAE,+BAA+B;EAC/C,gBAAgB;;AAElB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;;;EAGE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;;AAGA,gBAAgB;;;;AAIhB;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;;;AAIA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["\n\n.genieepro-font{\n  font-weight:750 ;\n  color:  #BABABA;\n  \n\n}\n\n.circle-icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px; /* Adjust the width as needed */\n  height: 40px; /* Adjust the height as needed */\n  border-radius: 50%;\n  background-color: #FFFFFF; /* Adjust the background color as needed */\n \n}\n\n.g-icon {\n  \n  color: #000000; /* Adjust the color as needed */\n  font-size: large;\n\n}\n\n.brand-container {\n  display: flex;\n  align-items: center;\n}\n.sidebar-link {\n  text-decoration: none;\n  color: inherit; \n}\n\n.sidebar-link:hover,\n.sidebar-link:focus,\n.sidebar-link:active {\n  text-decoration: none;\n  color: inherit;\n}\n\n.sidebar-link.active {\n  color: inherit;\n}\n\n\n/* Sidebar.css */\n\n\n\n.sidebar.dark-mode {\n  background-color: #121212;\n  color: #fff;\n}\n\n.brand-container {\n  color: #000;\n}\n\n.brand-container.dark-mode {\n  color: #fff;\n}\n\n\n\n.sidebar-link {\n  color: #000;\n}\n\n.sidebar-link.dark-mode {\n  color: #fff;\n}\n\n.delete-all {\n  color: red;\n}\n\n.delete-all.dark-mode {\n  color: #ff6b00;\n}\n\n.file-item {\n  color: #000;\n}\n\n.file-item.dark-mode {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
