import React from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import Login from "./components/login/Login";
import Signup from "./components/signup/SignUp";
import DataGeniePage from "./components/DataGeniee/DataGeniee";
import WelcomePage from "./components/welcomePage/welcomePage";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import OTPVerificationPage from "./components/OTPVerificationPage/OTPVerificationPage";
import Sidebar from "./components/sidebar/Sidebar";
import ConfirmMail from './components/ConfirmMail/ConfirmMail';
import PrivateRoute from './helpers/PrivateRoutes';

function MainContent({ interactionMode, setInteractionMode }) {
  const location = useLocation();
  const showSidebar = !location.pathname.startsWith('/auth');
console.log("Current location:", location.pathname);
  return (
    <>
      <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/welcome" element={<WelcomePage />} />
          <Route path="/auth/otp-verification" element={<OTPVerificationPage />}/>
          <Route path="/auth/confirm-mail" element={<OTPVerificationPage/>} />
          <Route path="/data-geniee" element={
            <PrivateRoute>
            <DataGeniePage/>
            </PrivateRoute>
            } />
            {/* <Route path="/data-geniee" element={
            <PrivateRoute>
            <DataGeniePage/>
            </PrivateRoute>
            } /> */}
          <Route path="/" element={<Navigate to="/auth/login" />} />
        </Routes>
    </>
  );
}
export default MainContent;
