import React from "react";
import { Modal, Button } from "react-bootstrap";

const SessionModal = ({ show, onHide, onContinue }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Active Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        There is already a session running. Do you want to continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onContinue}
          style={{ backgroundColor: "#8A2BE2" }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionModal;
