import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import TruncatedFileName from '../../helpers/TruncateFileName';
import axiosInstance from '../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "../ThemeContext";
import "./Multiselect.css";

const DropdownContainer = styled.div`
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusts spacing between items */
  padding: 5px 12px;
  cursor: pointer;
  position: relative; /* Ensures the icon positioning is relative to this container */
`;

const DropdownList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  margin-top:1px;
`;

const DropdownItem = styled.li`
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: ${props => props.selected ? '#FFF5EB' : 'white'};
  font-weight: ${props => props.selected ? 'bold' : 'normal'};
`;

const Checkbox = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 25%; /* This makes the checkbox circular */
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.checked ? '#FF9800' : 'white'};
  color: white;
  cursor: pointer; /* Adds a pointer cursor on hover */
  transition: all 0.2s ease-in-out; /* Smooth transition for background color change */

  &:hover {
    border-color: #FF9800;
  }

  &:after {
    display: ${props => props.checked ? 'block' : 'none'};
    font-size: 14px;
    color: white;
  }
`;

const NewButton = styled.button`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
`;

const MultiSelect = ({ fileResponse, sessionId, setMessages }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const toggleItem = (item) => {
    console.log('item', item);
    setSelectedItems(selectedItems.includes(item)
      ? selectedItems.filter(i => i !== item)
      : [...selectedItems, item]
    );
  };
console.log('selectedItems', selectedItems);

  useEffect(() => {
    if(selectedItems.length){
      handleSelectChange(selectedItems)
    }
  }, [selectedItems])

  const currentTime = new Date().toLocaleTimeString();
  
  const handleSelectChange = async (selected) => {
    try {
    
      const payload = {
        id: sessionId,
        files: [], // Clear files array before adding new sheet
      };
  
      fileResponse.forEach(file => {
        const sheetsForFile = selected.filter(sheet => file?.sheets?.includes(sheet));
        payload.files.push({
          fileName: file.fileName,
          sheets: sheetsForFile,
        });
      });
  
      const response = await axiosInstance.post(
        `doc-genie/select-and-preview-sheets?id=${sessionId}`,
        payload
      );
      if(response?.data?.type === 'Error'){
        const message =response?.data?.value;
        const newMessage = {
          sender: "System",
          type: "error",
          text: message,
          timestamp: currentTime,
        };
        setMessages(prevMessages => [...prevMessages, newMessage]);
      }
  
      if(response?.data?.isPrime === false){
        // Redirect to pricing page if the user is not a prime user
        navigate('/auth/welcome');
      }
  
      // setTableResponse(response?.data?.files);
  
      if (response?.data?.files.length === 0) {
        const message =response?.data?.messages;
        const newMessage = {
          sender: "System",
          type: "error",
          text: message,
          timestamp: currentTime,
        };
        setMessages(prevMessages => [...prevMessages, newMessage]);

      } else {
        // Update messages with the response
        const newMessage = {
          sender: "User 2",
          type: "table",
          table: response?.data?.files,
          timestamp: currentTime,
        };
        setMessages(prevMessages => [...prevMessages, newMessage]);
      }
    } catch (error) {
      console.error("Error processing payload:", error.message);
      if (
        error.response &&
        error.response.data.msg === "Invalid session" &&
        error.response.status === 401
      ) {
        // Navigate the user to "/"
        navigate("/");
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { isDarkMode } = useTheme();


  return (
    <DropdownContainer ref={dropdownRef} className='border border-0 position-relative ' style={fileResponse.length &&isOpen  ? { top: "68px" } : {}}>
      <DropdownHeader onClick={toggleDropdown} className={`${
        isDarkMode ? "dark-mode-black" : ""}`
      }>
        <img
          style={{ width: "18px", color: "white" }}
          src={require("../../icons/File.svg").default}
          alt="DataGenie Logo"
        />
        <span style={{ flex: 1, textAlign: 'center' }}>{selectedItems.length} Select Sheets</span>
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ marginLeft: 'auto' }}
        />
      </DropdownHeader>
      {isOpen && (
        <DropdownList className={` border-2 ${
                                isDarkMode ? "dark-mode-black" : ""
                              }`}>
          {fileResponse.map(file => (
            file.sheets.map((sheet, index)=>(
            <DropdownItem
              key={index}
              selected={selectedItems.includes(sheet)}
              onClick={() => toggleItem(sheet)}
              lassName={`${
                isDarkMode ? "dark-mode-black" : ""
              }`}
            >
              <Checkbox checked={selectedItems.includes(sheet)} />
              <TruncatedFileName fileName={sheet} maxLength={25} />
            </DropdownItem>
            ))
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default MultiSelect;
