// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-page {
    min-height: 100vh;
    
  }

  .light-mode{
    background-color: #f0f0f0;
  }
  
  .model-card {
    transition: transform 0.3s ease;
  }
  
  .model-card:hover {
    transform: translateY(-5px);
    transform: scale(1.15);
  }
  
  .icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/welcomePage/WelcomePage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;EAEnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,2BAA2B;IAC3B,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".welcome-page {\n    min-height: 100vh;\n    \n  }\n\n  .light-mode{\n    background-color: #f0f0f0;\n  }\n  \n  .model-card {\n    transition: transform 0.3s ease;\n  }\n  \n  .model-card:hover {\n    transform: translateY(-5px);\n    transform: scale(1.15);\n  }\n  \n  .icon-wrapper {\n    width: 60px;\n    height: 60px;\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .cursor-pointer {\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
