import React from 'react';
import { Form } from 'react-bootstrap';

const DarkModeToggle = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <Form.Check 
      type="switch"
      id="dark-mode-switch"
      label={isDarkMode ? "Dark Mode" : "Light Mode"}
      checked={isDarkMode}
      onChange={toggleDarkMode}
      className="dark-mode-toggle"
    />
  );
};

export default DarkModeToggle;