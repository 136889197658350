import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTheme } from "../ThemeContext";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword, sendOTP, verifyOTP } from "../../services/ForgotPassword";
import { useUserContext } from "../../helpers/UserContext";

const OTPVerificationPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isConfirmPassword, setConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const location = useLocation();
  const { userData } = useUserContext();
  const { isDarkMode } = useTheme();
  
  useEffect(() => {
    const origin = location?.state?.from;
    const userMail = location?.state?.userMail;
  
    setEmail(userMail || userData?.email);
  
    if (origin === "SignUp" || origin === "Login") {
      setType("EMAIL");
    } else if (origin === "ForgotPassword") {
      setType("PASSWORD");
    }
  }, [location]);
  

  const sendOtpIfNeeded = async () => {
    if (email) {
      try {
        await sendOTP(email, type);
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
    }
  };
  useEffect(() => {
    sendOtpIfNeeded();
  }, [email, type]);

  const handleChange = (element, index) => {
    const newOtp = [...otp];
    if (element.value === "" && element.previousSibling) {
      element.previousSibling.focus();
      newOtp[index] = "";
      setOtp(newOtp);
      return;
    }
    if (isNaN(element.value)) return;
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message on submit
    setSuccessMessage(""); // Reset success message on submit

    if (type === "PASSWORD") {
      try {
        const response = await verifyOTP(email, otp.join(""), type);
        if (response === "success") {
          setSuccessMessage("Verified successfully!");
          setConfirmPassword(true);
        } else if (response === "expired") {
          sendOtpIfNeeded();
          setErrorMessage("OTP has expired. Requested a new one.");
        } else {
          setErrorMessage("Invalid OTP. Please try again.");
        }
      } catch (error) {
        setErrorMessage("An error occurred. Please try again.");
      }
    } else {
      try {
        const response = await verifyOTP(email, otp.join(""), type);

        if (response === "success") {
          setSuccessMessage("Verified successfully!");
          setTimeout(() => {
            navigate("/auth/welcome");
          }, 2000); // Navigate to home page after 2 seconds
        } else if (response === "expired") {
          setErrorMessage("OTP has expired. Please request a new one.");
        } else {
          setErrorMessage("Invalid OTP. Please try again.");
        }
      } catch (error) {
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setErrorMessage(""); // Reset error message
    setSuccessMessage(""); // Reset success message

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await resetPassword(email, newPassword);
      if (response === "success") {
        setSuccessMessage("Password reset successfully!");
        setTimeout(() => {
          navigate("/");
        }, 2000); // Navigate to home page after 2 seconds
      } else {
        setErrorMessage("Failed to reset password. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };
  const darkModeStyles = {
    backgroundColor: isDarkMode ? "#121212" : "#f5f5f5",
    color: isDarkMode ? "white" : "black",
  };

  const inputStyles = {
    backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
    color: isDarkMode ? "white" : "black",
    border: "none",
    boxShadow: "none",
  };

  return (
    <Container fluid className="vh-100 d-flex flex-column" style={darkModeStyles}>
      <Row className="py-3 px-3">
        <Col className="d-flex justify-content-between align-items-center">
          <Col>
            <div className="product-name">G GENIEEPRO</div>
          </Col>
          <div>
            <Button 
              variant={isDarkMode ? "outline-light" : "outline-secondary"} 
              size="sm"
              onClick={() => navigate("/")}
              >
              Back to Logn In
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="flex-grow-1 justify-content-center align-items-center">
        {!isConfirmPassword && (
          <Col xs={12} md={6} lg={4} className="text-center">
            <h1 className="mb-3 fw-bold" style={{ fontSize: "2.5rem" }}>
              Account Verification
            </h1>
            <h5 className="mb-5" style={{ color: isDarkMode ? "#aaa" : "#6c757d" }}>
              Please check your email and enter the code below.
            </h5>

            <Form onSubmit={handleSubmit}>
              <div className="rounded-4 p-4 shadow" style={{ width: "100%", backgroundColor: isDarkMode ? "#1e1e1e" : "white" }}>
                <div className="d-flex justify-content-between mb-4">
                  {otp.map((data, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      style={inputStyles}
                      maxLength="1"
                      className="form-control text-center mx-1 mb-3 otp-input"
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  ))}
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 py-3 rounded-pill otp-submit-button"
                  style={{ backgroundColor: "#8A2BE2", borderColor: "#8A2BE2" }}
                >
                  Submit OTP
                </Button>

                {/* Error message */}
                {errorMessage && <p className="err-msg text-center mt-3 text-danger">{errorMessage}</p>}

                {/* Success message */}
                {successMessage && <p className="text-center mt-3 text-success">{successMessage}</p>}
              </div>
            </Form>
          </Col>
        )}

        {isConfirmPassword && (
          <Col xs={12} md={6} lg={4} className="text-center">
            <h1 className="mb-3 fw-bold" style={{ fontSize: "2.5rem" }}>
              Reset Your Password
            </h1>
            <h5 className="mb-5" style={{ color: "#6c757d" }}>
              Enter your new password below.
            </h5>

            <Form onSubmit={handlePasswordReset}>
              <div className="rounded-4 p-4 shadow" style={{ width: "100%" }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="New password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    className={`border-0 py-2 my-3 ${
                      isDarkMode ? "bg-dark text-light" : "bg-light"
                    }`}
                  />
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmNewPassword}
                    onChange={handleConfirmNewPasswordChange}
                    className={`border-0 py-2 my-3 ${
                      isDarkMode ? "bg-dark text-light" : "bg-light"
                    }`}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 py-3 rounded-pill"
                  style={{ backgroundColor: "#8A2BE2", borderColor: "#8A2BE2" }}
                >
                  Reset Password
                </Button>

                {/* Error message */}
                {errorMessage && <p className="err-msg text-center mt-3 text-danger">{errorMessage}</p>}

                {/* Success message */}
                {successMessage && <p className="text-center mt-3 text-success">{successMessage}</p>}
              </div>
            </Form>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default OTPVerificationPage;
