import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainContent from "./MainContent";
import { ThemeProvider } from "./components/ThemeContext";
import { UserProvider } from "./helpers/UserContext";
import { AuthProvider } from "./helpers/AuthContext";

function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [interactionMode, setInteractionMode] = useState('');
  // const [files, setFiles] = useState([]);

  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <UserProvider>
            <MainContent
              interactionMode={interactionMode}
              setInteractionMode={setInteractionMode}
            />
          </UserProvider>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;