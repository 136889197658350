import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import AuthForm from "../formController/AuthForm";
import axiosInstance from "../../services/AxiosInstance";
import { useUserContext } from "../../helpers/UserContext";
import SessionModal from "../SessionModal/SessionModal";
import { useAuth } from "../../helpers/AuthContext";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isInvalid, setIsInvalid] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const { setUserData } = useUserContext();
  const navigate = useNavigate();
  const { login } = useAuth();

  const fields = [
    { label: "Email Address", type: "email", placeholder: "Enter Email Address", name: "email" },
    { label: "Password", type: "password", placeholder: "Enter Your Password", name: "password" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post("/mid-doc/doc-genie/login", formData);
      if (response.status === 200) {
        const userResponse = await axiosInstance.get("/mid-doc/doc-genie/user-info");
        login();

        const { pagesUsed = 0, pagesLeft = 0, verifiedUser, primeUser } = userResponse.data;
        const totalPages = pagesUsed + pagesLeft;
        const percentage = totalPages > 0 ? Math.round((pagesUsed / totalPages) * 100) : 0;

        setUserData({ ...userResponse.data, percentage });

        if (primeUser) {
          if (!verifiedUser) {
            navigate("/confirm-mail", { state: { userMail: formData.email, from: "Login" } });
          } else {
            navigate("/data-geniee");
          }
        } else {
          navigate("/auth/welcome");
        }
      }
    } catch (error) {
      if (error.response?.status === 429) {
        setShowSessionModal(true);
      } else {
        setIsInvalid(true);
      }
    }
  };

  const handleContinue = async () => {
    if (formData.email) {
      try {
        const sessionResponse = await axiosInstance.delete(`/mid-doc/doc-genie/delete-session?mail=${formData.email}`);
        if (sessionResponse.data === "success") handleSubmit();
      } catch (error) {
        console.error("Error in forced login:", error);
      }
      setShowSessionModal(false);
    }
  };

  return (
    <Container fluid className="login-container">
      <Row className="header">
        <Col>
          <div className="product-name">G GENIEEPRO</div>
        </Col>
      </Row>
      <Row className="main-content pl-5">
        <Col md={6} lg={6} xs={12} className="welcome-text">
          <h1>Welcome Back!</h1>
          <p>We're Glad to have you back</p>
        </Col>
        <Col md={6} className="form-container">
          <AuthForm
            fields={fields}
            buttonText="Login"
            onSubmit={handleSubmit}
            setFormData={setFormData}
            formData={formData}
            isInvalid={isInvalid}
          />
        </Col>
      </Row>
      <Row className="footer">
        <Col>
          <p>
            New Here?{" "}
            <Link to="/auth/signup" className="create-account-link">
              Create An Account
            </Link>
          </p>
        </Col>
      </Row>
      <SessionModal
        show={showSessionModal}
        onHide={() => setShowSessionModal(false)}
        onContinue={handleContinue}
      />
    </Container>
  );
};

export default Login;
