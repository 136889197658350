import React, { useRef, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import MultiSelect from "../multiSelectDropdown/MultiSelectDropdown";
import ChatBox from "../ChatBox/ChatBox";
import Alert from "react-bootstrap/Alert";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../ThemeContext";
import "./DataG.css";

const dummyMessages = [
  {
    sender: "User 1",
    type: "user",
    text: "Hello, can you analyze the sales data for kjnjkbjbjbjbkhblhvlhvjhvhjvhjvhjvhjvjhvhjvQ1? can you analyze the sales data for kjnjkbjbjbjbkhblhvlhvjhvhjvhjvhjvhjvjhvhjvQ1?",
    timestamp: "10:00 AM",
  },
  {
    sender: "User 2",
    type: "other",
    text: "Certainly! I'd be happy to analyze the sales data for Q1. Let me take a look at the information and provide you with a summary.",
    timestamp: "10:01 AM",
  },
  {
    sender: "User 2",
    type: "dataframe",
    table: [
      { Month: "January", Sales: 120000, Expenses: 80000, Profit: 40000 },
      { Month: "February", Sales: 130000, Expenses: 85000, Profit: 45000 },
      { Month: "March", Sales: 140000, Expenses: 90000, Profit: 50000 },
    ],
    timestamp: "10:02 AM",
  },
  {
    sender: "User 2",
    type: "other",
    text: "Based on the Q1 sales data, here's a summary of the key findings:",
    timestamp: "10:03 AM",
  },
  {
    sender: "User 2",
    type: "other",
    text: "1. Total Q1 Sales: $390,000\n2. Total Q1 Expenses: $255,000\n3. Total Q1 Profit: $135,000\n4. Month-over-month sales growth: 8.33% (Jan to Feb), 7.69% (Feb to Mar)",
    timestamp: "10:04 AM",
  },
  {
    sender: "User 1",
    type: "user",
    text: "Great, can you show me a plot of the sales trend?",
    timestamp: "10:05 AM",
  },
  {
    sender: "User 2",
    type: "plot",
    image:
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==",
    timestamp: "10:06 AM",
  },
  {
    sender: "User 2",
    type: "other",
    text: "Here's a plot showing the sales trend for Q1. As you can see, there's a steady increase in sales from January to March.",
    timestamp: "10:07 AM",
  },
  {
    sender: "User 1",
    type: "user",
    text: "What's the projected sales for Q2 based on this trend?",
    timestamp: "10:08 AM",
  },
  {
    sender: "System",
    type: "chatloader",
    text: "Tailoring your answer...",
    timestamp: "10:08 AM",
  },
  {
    sender: "System",
    type: "error",
    text: "An error occurred while processing your request. Please try again.",
    timestamp: "10:10 AM",
  },
];

const DataG = ({ onHide, sessionId, notifyMessage, fileResponse }) => {
  const inputRef = useRef(null);

  const [messages, setMessages] = useState(dummyMessages);
  const [isApiDone, setApiDone] = useState(false);
  const handleOpenModal = () => {
    onHide(true);
  };

  const { isDarkMode } = useTheme();

  return (
    <>
      <Row className="m-0">
        <div className=" d-flex justify-content-between align-items-center px-5 pt-4">
        <div className="sub-logo"
                style={{
                  backgroundColor: "#FF6B00",
                  width: "25px",
                  borderRadius: "9px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBlock: "6px",
                  paddingInline: "15px",
                }}
              >
                <img
                  style={{ width: "18px", color: "white" }}
                  src={require("../../icons/DataGenieLogo.svg").default}
                  alt="DataGenie Logo"
                />
              </div>
          <h4 className="main-logo ms-0 mt-0" style={{ color: "#FF6B00" }}>
            DataGenie
          </h4>
          {fileResponse.length !== 0 && (
            <div
              className={`position-absolute start-50 translate-middle-x z-2 ${
                isDarkMode ? "dark-mode-black" : ""
              }`}
            >
              <MultiSelect
                fileResponse={fileResponse}
                sessionId={sessionId}
                setMessages={setMessages}
              />
            </div>
          )}
          <Button
            variant="outline-secondary"
            className="rounded-pill"
            onClick={handleOpenModal}
          >
            + New
          </Button>
        </div>
      </Row>
      <div className="m-0">
        <ChatBox
          sessionId={sessionId}
          setMessages={setMessages}
          messages={messages}
        />
        {false && (
          <div className="position-absolute top-50 start-50 translate-middle">
            <h1 className="text-center">Welcome To DataGeniee</h1>
            <h2 className="text-center fs-5">Excel Analysis Made Simple</h2>
            <Alert show={notifyMessage} variant={notifyMessage.type}>
              <p className="text-center">
                {notifyMessage.message}
                <span className="p-1">
                  {notifyMessage.type === "success" && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="me-2"
                      style={{ color: "green" }}
                    />
                  )}
                  {notifyMessage.type === "danger" && (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="me-2"
                      style={{ color: "red" }}
                    />
                  )}
                </span>
              </p>
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default DataG;
