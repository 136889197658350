import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTheme } from "../ThemeContext";
import { sendOTP, verifyOTP } from "../../services/ForgotPassword";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmMail = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const [email, setEmail] = useState("");
  const [isSendOTP, setSendOTP] = useState(false);
  const location = useLocation();
  const [buttonName, setButtonName] = useState("Submit OTP");

  useEffect(() => {
    if (location.state && location.state.userMail) {
      setEmail(location.state.userMail);
    } else {
      console.log("NO mail");
      // navigate('/');
    }
  }, [location.state]);

  useEffect(() => {
    console.log("EMAIL render", email);
    if (email) {
      handleSendOTP();
    }
  }, [email]);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const { isDarkMode } = useTheme();
  const mailId = "Email@email.com";

  const handleChange = (element, index) => {
    const newOtp = [...otp];

    if (element.value.length > 1) {
      return;
    }

    if (element.value === "") {
      newOtp[index] = "";
      setOtp(newOtp);
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    } else {
      if (isNaN(element.value)) return false;
      newOtp[index] = element.value;
      setOtp(newOtp);
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleVarifyMail = async (e) => {
    e.preventDefault();
    console.log("Submitted OTP:", otp.join(""));
    try {
      const response = await verifyOTP(email, otp.join(""), "EMAIL");
      if (response === "success") {
        setButtonName("Verified!");
      }
      if (response === "expired") {
        setSendOTP(false);
        setButtonName("Submit OTP");
      }
    } catch (error) {
      // Handle error
      // setError("Incorrect OTP. Please enter the correct OTP.");
    }
  };

  const darkModeStyles = {
    backgroundColor: isDarkMode ? "#121212" : "#f5f5f5",
    color: isDarkMode ? "white" : "black",
  };

  const inputStyles = {
    backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
    color: isDarkMode ? "white" : "black",
    border: "none",
    boxShadow: "none",
  };

  const handleSendOTP = async (event) => {
    event?.preventDefault();
    try {
      const response = await sendOTP(email, "EMAIL");
      // Check response data if needed
      console.log("response", response);
      if (response === "success") {
        setSendOTP(true);
        // setError("OTP sent successfully. Please check your email.");
      } else if (response === "invalid") {
        // setError("This email doesn't exist, please try again.");
      }
    } catch (error) {
      // setError("Failed to send OTP. Please try again.");
    }
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column"
      style={darkModeStyles}
    >
      <Row className="py-3 px-3">
        <Col className="d-flex justify-content-between align-items-center">
          <Col>
            <div className="product-name">G GENIEEPRO</div>
          </Col>
          <div>
            <Button
              variant={isDarkMode ? "outline-light" : "outline-secondary"}
              size="sm"
              onClick={() => navigate("/")}
            >
              Back to Sign In
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="flex-grow-1 justify-content-center align-items-center">
        <Col xs={12} md={6} lg={4} className="text-center">
          <h1 className="mb-3 fw-bold" style={{ fontSize: "2.5rem" }}>
            We've Sent You A Code to
          </h1>
          <h2 className="mb-3 fw-bold">{email}</h2>
          <h5
            className="mb-5"
            style={{ color: isDarkMode ? "#aaa" : "#6c757d" }}
          >
            Please check your email and enter the code below.
          </h5>
          <Form>
            <div
              className="rounded-4 p-4 shadow"
              style={{
                width: "100%",
                backgroundColor: isDarkMode ? "#1e1e1e" : "white",
              }}
            >
              <div className="d-flex justify-content-between mb-4">
                {otp.map((data, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    maxLength="1"
                    className="form-control text-center mx-1 mb-3 otp-input"
                    style={inputStyles}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && !e.target.value) {
                        if (e.target.previousSibling) {
                          e.target.previousSibling.focus();
                        }
                      }
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                ))}
              </div>
              {isSendOTP ? (
                <Button
                  variant={isDarkMode ? "light" : "primary"}
                  onClick={handleSendOTP}
                  className="w-100 py-3 rounded-pill otp-submit-button"
                  style={{
                    backgroundColor: "#8A2BE2",
                    borderColor: "#8A2BE2",
                  }}
                >
                  Request OTP
                </Button>
              ) : (
                <Button
                  variant={isDarkMode ? "light" : "primary"}
                  onClick={handleVarifyMail}
                  className="w-100 py-3 rounded-pill otp-submit-button"
                  style={{ backgroundColor: "#8A2BE2", borderColor: "#8A2BE2" }}
                >
                  {buttonName}
                </Button>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmMail;
