import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import PricingModal from "../Pricing/PricingModal";
import { ReactComponent as DataGenieLogo } from "../../icons/DataGenieLogo.svg";
import { ReactComponent as ClassicGenieLogo } from "../../icons/ClassicGenie.svg";
import { ReactComponent as DocGenieLogo } from "../../icons/DocgenieLogo.svg";
import "./WelcomePage.css";
import { useTheme } from "../ThemeContext";
import { useUserContext } from "../../helpers/UserContext";

const WelcomePage = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleHidePricingModal = () => {
    setShowModal(false);
  };
  const { userData } = useUserContext();
  useEffect(() => {
    if(!userData?.isPrimeUser)
    setShowModal(true);
  }, []);

  const { isDarkMode } = useTheme();

  return (
    <Container
      fluid
      className={`welcome-page d-flex align-items-center justify-content-center ${
        isDarkMode ? "dark-mode" : "light-mode"
      }
      }`}
    >
      <div className="text-center">
        <h1 className="mb-3">Welcome To GenieePro</h1>
        <p className="mb-5">Choose a Model to get started!</p>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} md={6} className="mb-4">
            <Card
              className={`model-card h-100 cursor-pointer ${
                isDarkMode ? "dark-mode-fade" : ""
              }`}
              onClick={() => navigate("/data-geniee")}
            >
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <div
                  className="icon-wrapper mb-3"
                  style={{ backgroundColor: "#FF9500" }}
                >
                  <DataGenieLogo />
                </div>
                <Card.Title>DataGenie</Card.Title>
                <Card.Text>Genie for Data Analysis</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col xs={12} sm={6} md={4} className="mb-4">
            <Card
              className={`model-card h-100 cursor-pointer ${
                isDarkMode ? "dark-mode-fade" : ""
              }`}
              // onClick={() => navigate('/classicgenie')}
            >
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <div
                  className="icon-wrapper mb-3"
                  style={{ backgroundColor: "#AF52DE" }}
                >
                  <ClassicGenieLogo />
                </div>
                <Card.Title>ClassicGenie</Card.Title>
                <Card.Text>Great for Everyday Tasks</Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
          <Col xs={12} sm={6} md={6} className="mb-4">
            <Card
              className={`model-card h-100 cursor-pointer ${
                isDarkMode ? "dark-mode-fade" : ""
              }`}
              onClick={() => navigate("/doc-genie")}
            >
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <div
                  className="icon-wrapper mb-3"
                  style={{ backgroundColor: "#007AFF" }}
                >
                  <DocGenieLogo />
                </div>
                <Card.Title>DocGenie</Card.Title>
                <Card.Text>Genie for DOC Analysis</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <PricingModal show={showModal} onHide={handleHidePricingModal} />
    </Container>
  );
};

export default WelcomePage;
