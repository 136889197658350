import React from "react";
import { Nav, Navbar, Stack } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faG,
  faChevronRight,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css"; // Ensure this import path is correct for your CSS file
import TruncatedFileName from "../../helpers/TruncateFileName";
import { useTheme } from "../ThemeContext";

const Sidebar = ({ setFileResponse, setFiles, files }) => {
  const { isDarkMode } = useTheme();

  const handleDeleteAll = () => {
    setFiles([]);
    setFileResponse([]);
  };

  return (
    <Navbar
      expand="lg"
      className={`flex-column align-items-start vh-100 p-0 sidebar ${isDarkMode ? 'dark-mode' : ''}`}
    >
      <Navbar.Brand href="#home" className={`w-100 p-3 mb-4 brand-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="circle-icon">
          <FontAwesomeIcon icon={faG} className="g-icon" />
        </div>
        <span className="genieepro-font mx-2">GENIEEPRO</span>
      </Navbar.Brand>
      <Nav className="flex-column w-100">
        <NavLink
          to="/data-geniee"
          className={({ isActive }) => `p-3 sidebar-link ${isActive ? 'active' : ''} ${isDarkMode ? 'dark-mode' : ''}`}
        >
          <Stack
            gap={2}
            direction="horizontal"
            className="sidebar-icon justify-content-between"
          >
            <div className="d-flex align-items-center justify-content-center gap-1">
              <div
                style={{
                  backgroundColor: "#FF6B00",
                  width: "25px",
                  borderRadius: "9px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBlock: "6px",
                  paddingInline: "15px",
                }}
              >
                <img
                  style={{ width: "18px", color: "white" }}
                  src={require("../../icons/DataGenieLogo.svg").default}
                  alt="DataGenie Logo"
                />
              </div>
              <div className="sidebar-text">DataGenie</div>
            </div>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="active p-3"
              style={{ height: "12px" }}
            />
          </Stack>
        </NavLink>
        <Stack
          direction="horizontal"
          gap={3}
          className="w-100 px-3"
          style={{ fontSize: "12px" }}
        >
          <p>Files</p>
          <p
            className={`text-danger text-xs ms-auto delete-all ${isDarkMode ? 'dark-mode' : ''}`}
            onClick={handleDeleteAll}
            style={{ cursor: "pointer" }}
          >
            Delete All
          </p>
        </Stack>
        {files?.map((file, index) => (
          <div href={`#file${index}`} className={`ps-3 pb-3 file-item ${isDarkMode ? 'dark-mode' : ''}`} key={index}>
            <img
              style={{ width: "22px", color: "white" }}
              src={require("../../icons/File.svg").default}
              alt="DataGenie Logo"
              className="pe-2"
            />
            <TruncatedFileName fileName={file.name} maxLength={15} />
          </div>
        ))}
      </Nav>
      <div className="mt-auto w-100 p-3">
        <div className="d-flex align-items-center mt-3">
          <FontAwesomeIcon icon={faUser} className="me-2" />
          <div>
            <small>User Name</small>
            <br />
            <small className="text-muted">Upcoming Due: Jul 1</small>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Sidebar;
