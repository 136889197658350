// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body-scrollable {
    max-height: 60vh; 
    overflow-y: auto;
    padding: 20px; 
  }
  
  .terms-conditions-text {
    height: 60vh;
    text-align: left;
    line-height: 1.6; 
    margin: 0; 
    padding: 0; 
  }
  
  .terms-conditions-text p {
    margin-bottom: 1em; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Terms&Conditions/Termsandconditions.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".modal-body-scrollable {\n    max-height: 60vh; \n    overflow-y: auto;\n    padding: 20px; \n  }\n  \n  .terms-conditions-text {\n    height: 60vh;\n    text-align: left;\n    line-height: 1.6; \n    margin: 0; \n    padding: 0; \n  }\n  \n  .terms-conditions-text p {\n    margin-bottom: 1em; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
