// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .modal-header {
        border-bottom: none!important;
    }
    .modal-footer {
        justify-content: center !important;
        border-top: none!important;
    }
  `, "",{"version":3,"sources":["webpack://./src/components/GuidelinesModal/guidelinesmodal.css"],"names":[],"mappings":";IACI;QACI,6BAA6B;IACjC;IACA;QACI,kCAAkC;QAClC,0BAA0B;IAC9B","sourcesContent":["\n    .modal-header {\n        border-bottom: none!important;\n    }\n    .modal-footer {\n        justify-content: center !important;\n        border-top: none!important;\n    }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
